import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { getAllTransitionsForEveryProcess } from '../../transactions/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { transitions } from '../../transactions/transactionProcessBooking';

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/DashboardPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/DashboardPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/DashboardPage/FETCH_ORDERS_OR_SALES_ERROR';

export const FETCH_LISTINGS_REQUEST = 'app/ManageListingsPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/ManageListingsPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/ManageListingsPage/FETCH_LISTINGS_ERROR';

export const ADD_OWN_ENTITIES = 'app/ManageListingsPage/ADD_OWN_ENTITIES';

export const SEND_UPDATE_PROFILE_REQUEST = 'app/ListingPage/SEND_UPDATE_PROFILE_REQUEST';
export const SEND_UPDATE_PROFILE_ERROR = 'app/ListingPage/SEND_UPDATE_PROFILE_ERROR';
// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
};

export default function dashboardPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

export const addOwnEntities = sdkResponse => ({
  type: ADD_OWN_ENTITIES,
  payload: sdkResponse,
});

export const queryListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const sendUpdateProfileRequest = () => ({ type: SEND_UPDATE_PROFILE_REQUEST });
export const sendUpdateProfileError = (e) => ({ type: SEND_UPDATE_PROFILE_ERROR, payload: e });

// ================ Thunks ================ //

const DASHBOARD_PAGE_SIZE = 50;

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { tab } = params;

  const onlyFilterValues = {
    orders: 'order',
    sales: 'sale',
    bookings: 'sale',
    discount:'sale'
  };


  const onlyFilter = onlyFilterValues[tab];
  if (!onlyFilter && tab!='bookings' &&tab!='discount') {
    return Promise.reject(new Error(`Invalid tab for DashboardPage: ${tab}`));
  }

  dispatch(fetchOrdersOrSalesRequest());

  const { page = 1 } = parse(search);

  const transitionParams = [
    transitions.PROVIDER_ACCEPT, 
    transitions.PROVIDER_ACCEPT_OFF_SESSION, 
    transitions.CONFIRM_PAYMENT, 
    transitions.AUTO_NEXT_DAY, 
    transitions.PROVIDER_MODIFY_REQUESTING, 
    transitions.CUSTOMER_MODIFY_REQUEST_ACCEPT,
    transitions.CUSTOMER_MODIFY_REQUEST_DECLINE,
    transitions.ACCEPT,
    transitions.PROVIDER_MODIFY_REQUEST_ACCEPT_OFF_SESSION,
    transitions.CUSTOMER_MODIFY_REQUESTING,
    transitions.PROVIDER_MODIFY_REQUEST_ACCEPT,
    transitions.PROVIDER_MODIFY_REQUEST_DECLINE,
    transitions.OPERATOR_ACCEPT,
    transitions.INITIATE_AUTO_PAYMENT,
    transitions.INITIATE_MANUAL_PAYMENT,
    transitions.CONFIRM_MANUAL_PAYMENT,
    transitions.PAYMENT_SUCCESS,
    transitions.OPERATOR_ACCEPT,
    transitions.OPERATOR_ACCEPT,
  ]

  const apiQueryParams = {
    only: 'sale',
    // lastTransitions: getAllTransitionsForEveryProcess(),
    lastTransitions: transitionParams,
    include: [
      'listing',
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'booking',
    ],
    'fields.transaction': [
      'processName',
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'lineItems',
    ],
    'fields.listing': ['title', 'availabilityPlan', 'publicData.listingType'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    // perPage: DASHBOARD_PAGE_SIZE,
  };

  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchOrdersOrSalesSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};


export const queryOwnListings = () => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest());
  return sdk.ownListings
    .query()
    .then(response => {
       // Handle the response containing the listings
       const listings = response.data;
       return listings;
    })
    .catch(e => {
      dispatch(queryListingsError(storableError(e)));
      throw e;
    });
};

export const updateProfile = (checkedList, discountDetails) => (dispatch, getState, sdk) => {
  dispatch(sendUpdateProfileRequest());

  const updatePromises = checkedList.map(listId => {
    return sdk.ownListings.update(
      {
        id: listId,
        publicData: {
          discountDetails: discountDetails,
        },
      },
      {
        expand: true,
        include: ["images"],
      }
    ).then(res => {
      // Handle each response if needed
    })
    .catch(e => {
      dispatch(sendUpdateProfileError(storableError(e)));
      console.log("Error from discount update - section1",e);
    });
  });

  return Promise.all(updatePromises)
    .then(responses => {
      return responses;
      // Handle all responses if needed
    })
    .catch(e => {
      // Handle overall error if needed
      console.log("Error from discount update - section2",e); 
    });
};

