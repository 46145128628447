import React from 'react';
import { bool, string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_CUSTOMER_TOTAL_PRICE, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const { Money } = sdkTypes;

// Validate the assumption that the total price exists and the amount
// is zero or positive.
const isValidTotalPrice = totalPriceLineItem => {
  return (
    totalPriceLineItem &&
    totalPriceLineItem.lineTotal instanceof Money &&
    totalPriceLineItem.lineTotal.amount >= 0
  );
};

const LineItemCustomerTotalPriceMaybe = props => {
  const { lineItems, isCustomer, marketplaceName, currency, intl, listingType, isHalfRefund, isProviderDeclined } = props;

  // If the total price is passed it will be shown as a fee already added to the total price
  let totalPriceItem = null;


  // const totalPrice = (lineItems?.find(x => x.code == 'line-item/hour')?.unitPrice?.amount * lineItems?.find(x => x.code == 'line-item/hour').quantity) + lineItems?.find(x => x.code == 'line-item/customer-commission')?.lineTotal?.amount;

  // Replace this with your actual condition to determine if it is a customer

  let totalPrice =
    (lineItems?.find(x => x.code == 'line-item/hour')?.unitPrice?.amount * lineItems?.find(x => x.code == 'line-item/hour')?.quantity) +
    (lineItems?.find(x => x.code == (isCustomer ? 'line-item/customer-commission' : 'line-item/provider-commission'))?.lineTotal?.amount || 0) +
    (lineItems?.find(x => x.code == 'line-item/travel-charges-flat')?.unitPrice?.amount || 0) +
    (lineItems?.find(x => x.code == 'line-item/travel-charges-distance')?.unitPrice?.amount || 0) +
    (lineItems?.find(x => x.code == 'line-item/price-adjustment')?.unitPrice?.amount || 0) +
    (isCustomer ? (lineItems?.find(x => x.code == 'line-item/customer-discount')?.lineTotal?.amount || 0) : (lineItems?.find(x => x.code == 'line-item/provider-discount')?.lineTotal?.amount || 0)) || 0;


  if (listingType == 'package') {
    let packageTotal = 0;
    const filtered = isCustomer ? lineItems?.filter(x => x.code.includes('line-item/package')) : lineItems?.filter(x => x.code.includes('line-item/provider-package'));
    filtered?.map(item => {
      packageTotal = packageTotal + item.unitPrice.amount * item.quantity.d[0];
    });
    totalPrice =
      packageTotal +
      (lineItems?.find(x => x.code == 'line-item/travel-charges-flat')?.unitPrice?.amount || 0) +
      (lineItems?.find(x => x.code == 'line-item/travel-charges-distance')?.unitPrice?.amount || 0) +
      (lineItems?.find(x => x.code == 'line-item/price-adjustment')?.unitPrice?.amount || 0) +
      (lineItems?.find(x => x.code == (isCustomer ? 'line-item/customer-commission' : 'line-item/provider-commission'))?.lineTotal?.amount || 0) +
      (isCustomer ? (lineItems?.find(x => x.code == 'line-item/customer-discount')?.lineTotal?.amount || 0) : (lineItems?.find(x => x.code == 'line-item/provider-discount')?.lineTotal?.amount || 0)) ;
  }

  const price = new Money(totalPrice, currency);

  const zeroMoney = new Money(0, currency);

  const formattedTotalPrice = isProviderDeclined ? formatMoney(intl, zeroMoney) : totalPrice ? formatMoney(intl, price) : null;

    totalPriceItem = (
      <>
        <div className={css.lineItem}>
          <span className={css.itemLabel}>
            {(listingType == 'package' ?

              (isCustomer ?
                <FormattedMessage
                  id="OrderBreakdown.total"
                  values={{ marketplaceName, role: 'customer' }}
                /> : 
                <FormattedMessage
                  id="OrderBreakdown.totalProvider"
                  values={{ marketplaceName, role: 'provider' }}
                />) :
                (isCustomer ?
                  <FormattedMessage
                    id="OrderBreakdown.total"
                    values={{ marketplaceName, role: 'customer' }}
                  /> :
                  <FormattedMessage
                    id="OrderBreakdown.totalProvider"
                    values={{ marketplaceName, role: 'provider' }}
                  />)
            )}
          </span>
          <span className={css.itemValue}>{formattedTotalPrice}</span>
        </div>
      </>
    );

  return totalPriceItem;
};

LineItemCustomerTotalPriceMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  isCustomer: bool.isRequired,
  marketplaceName: string.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemCustomerTotalPriceMaybe;
