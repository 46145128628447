import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_TRAVEL_CHARGES_DISTANCE, LINE_ITEM_TRAVEL_CHARGES_FLAT, LINE_ITEM_EVENT_TRAVEL_CHARGES_FLAT, LINE_ITEM_EVENT_TRAVEL_CHARGES_DISTANCE, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemTravelChargesMayBe = props => {
  const { lineItems, intl, setDistance, distance,isProvider } = props;

  const travelChargesLineItem = lineItems.find(
    item => 
      (!item.reversal && 
      (item.code === LINE_ITEM_TRAVEL_CHARGES_DISTANCE || item.code === LINE_ITEM_TRAVEL_CHARGES_FLAT))
  );
  
  
  // const eventTravelFeeLineItem = lineItems.find(
  //   item => 
  //     (!item.reversal && 
  //     (item.code === LINE_ITEM_EVENT_TRAVEL_CHARGES_DISTANCE || item.code === LINE_ITEM_EVENT_TRAVEL_CHARGES_FLAT))
  // );

  
  const distanceMayBe = travelChargesLineItem?.travelDistance;
  if (distanceMayBe){
    setDistance(distanceMayBe);
  }

  return (
    travelChargesLineItem?<>
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="OrderBreakdown.travelCharges" />        
      </span>
      <span className={css.itemValue}>{formatMoney(intl, (travelChargesLineItem.lineTotal))}</span>
    </div>
    {/* {isProvider?
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="OrderBreakdown.eventTravelFee" />        
      </span>
      <span className={css.itemValue}>{formatMoney(intl, (eventTravelFeeLineItem?.unitPrice))}</span>
    </div>
    :null} */}
    </>:null
    )

};

LineItemTravelChargesMayBe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemTravelChargesMayBe;
