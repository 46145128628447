import React from 'react';
import Decimal from 'decimal.js';

import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_CUSTOMER_COMMISSION, LINE_ITEM_PROVIDER_COMMISSION, propTypes } from '../../util/types';
import { resolveLatestProcessName, getProcess } from '../../transactions/transaction';

import css from './OrderBreakdown.module.css';

import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;

const lineItemsTotal = (lineItems, marketplaceCurrency) => {
  const amount = lineItems.reduce((total, item) => {
    return total.plus(item.lineTotal.amount);
  }, new Decimal(0));
  const currency = lineItems[0] ? lineItems[0].lineTotal.currency : marketplaceCurrency;
  return new Money(amount, currency);
};

const LineItemTotalAfterRefund = props => {
  const { transaction, isProvider, intl, lineItems, currency, isFullRefund, isHalfRefund, isPartialRefundAmount } = props;
  const processName = resolveLatestProcessName(transaction?.attributes?.processName);
  if (!processName) {
    return null;
  }
  
  const process = getProcess(processName);
  const isCompleted = process.isCompleted(transaction?.attributes?.lastTransition);
  const isRefunded = process.isRefunded(transaction?.attributes?.lastTransition);

  let providerTotalMessageId = 'OrderBreakdown.providerTotalDefault';
  if (isCompleted) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalReceived';
  } else if (isRefunded) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalRefunded';
  }

  let totalLabel = isProvider ? (
    <FormattedMessage id="OrderBreakdown.totalAfterRefundProvider" />
  ) : (
    isHalfRefund || isFullRefund ?
    'Cancel fee'
    :<FormattedMessage id="OrderBreakdown.totalAfterRefundCustomer" />
  );


  const totalPrice = isProvider
    ? transaction.attributes.payoutTotal
    : transaction.attributes.payinTotal;

  let formattedTotalPrice = formatMoney(intl, totalPrice);


  if(isFullRefund || (isHalfRefund && isProvider)) {
    formattedTotalPrice = formatMoney(intl, new Money(0, currency));
  } else if(isPartialRefundAmount) {
    formattedTotalPrice = formatMoney(intl, new Money(isPartialRefundAmount, currency));
  }

  if(isFullRefund && isProvider) {
    return null;
  }

  return (
    <>
      
      { isPartialRefundAmount ? 
        <div className={css.lineItemTotalRefund}>
          <div className={css.totalLabel}>{'Refund'}</div>
          <div className={css.totalPriceThin}>-{formattedTotalPrice}</div>
        </div>
      : null }
      <hr className={css.totalDivider} />
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>{totalLabel}</div>
        <div className={css.totalPrice}>{formattedTotalPrice}</div>
      </div>
    </>
  );
};

LineItemTotalAfterRefund.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemTotalAfterRefund;
