/**
 * This component will show the booking info and calculated total price.
 * I.e. dates and other details related to payment decision in receipt format.
 */
import React from 'react';
import { oneOf, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import {
  propTypes,
  LISTING_UNIT_TYPES,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
  LINE_ITEM_HOUR,
  LINE_ITEM_PRICE_ADJUSTMENT,
} from '../../util/types';

import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;

import LineItemBookingPeriod from './LineItemBookingPeriod';
import LineItemBasePriceMaybe from './LineItemBasePriceMaybe';
import LineItemSubTotalMaybe from './LineItemSubTotalMaybe';
import LineItemShippingFeeMaybe from './LineItemShippingFeeMaybe';
import LineItemPickupFeeMaybe from './LineItemPickupFeeMaybe';
import LineItemCustomerCommissionMaybe from './LineItemCustomerCommissionMaybe';
import LineItemCustomerCommissionRefundMaybe from './LineItemCustomerCommissionRefundMaybe';
import LineItemProviderCommissionMaybe from './LineItemProviderCommissionMaybe';
import LineItemProviderCommissionRefundMaybe from './LineItemProviderCommissionRefundMaybe';
import LineItemRefundMaybe from './LineItemRefundMaybe';
import LineItemTotalPrice from './LineItemTotalPrice';
import LineItemUnknownItemsMaybe from './LineItemUnknownItemsMaybe';

import css from './OrderBreakdown.module.css';
import LineItemExternalPaymentMaybe from './LineItemExternalPaymentMaybe';
import LineItemCustomerTotalPriceMaybe from './LineItemCustomerTotalPriceMaybe';
import { formatMoney } from '../../util/currency';
import LineItemTotalAfterRefund from './LineItemTotalAfterRefund';
import LineItemTravelChargesMayBe from './LineItemTravelChargesMaybe';
import LineItemDiscountMaybe from './LineItemDiscountMaybe';
import LineItemPriceAdjustmentMaybe from './LineItemPriceAdjustmentMaybe';
import LineItemProviderPartialRefundMaybe from './LineItemProviderPartialRefundMaybe';

export const OrderBreakdownComponent = props => {
//  var currTimeHr = new Date().getHours();
//  var currTimeMin = new Date().getMinutes();
//    if(currTimeMin%2 === 0){  
  
  const {
    rootClassName,
    className,
    userRole,
    transaction,
    booking,
    intl,
    dateType,
    timeZone,
    currency,
    marketplaceName,
    duration,
    commissions,
    listingType,
    setDistance,
    distance,
    showModifyButton
  } = props;


  const isCustomer = userRole === 'customer';
  const isProvider = userRole === 'provider';
  const allLineItems = transaction.attributes.lineItems || [];
  // We'll show only line-items that are specific for the current userRole (customer vs provider)
  const lineItems = allLineItems.filter(lineItem => lineItem.includeFor.includes(userRole));
  const unitLineItem = lineItems.find(
    item => LISTING_UNIT_TYPES.includes(item.code) && !item.reversal
  );
  // Line-item code that matches with base unit: day, night, hour, item
  const lineItemUnitType = unitLineItem?.code;

  const hasCommissionLineItem = lineItems.find(item => {
    const hasCustomerCommission = isCustomer && item.code === LINE_ITEM_CUSTOMER_COMMISSION;
    const hasProviderCommission = isProvider && item.code === LINE_ITEM_PROVIDER_COMMISSION;
    return (hasCustomerCommission || hasProviderCommission) && !item.reversal;
  });

  const classes = classNames(rootClassName || css.root, className);


  const unitPurchase = lineItems.find(item => item.code === LINE_ITEM_HOUR);
  let unitPrice = unitPurchase?.unitPrice;

  const customerCommission = lineItems.find(item => item.code === LINE_ITEM_CUSTOMER_COMMISSION);
  const customerPercentage = customerCommission?.percentage?.d[0];

  if (isCustomer) {
    const customerCommissionPrice = unitPrice?.amount * customerPercentage / 100;
    unitPrice = new Money((unitPrice?.amount + customerCommissionPrice), currency);
  }

  const isFullRefund = transaction.attributes.lastTransition == 'transition/customer-cancel-full-refund' || transaction.attributes.lastTransition == 'transition/customer-cancel-full-refund-off-session'
  const isHalfRefund = transaction.attributes.lastTransition == 'transition/customer-cancel-half-refund' || transaction.attributes.lastTransition == 'transition/customer-cancel-half-refund-off-session' || transaction.attributes.lastTransition == 'transition/customer-cancel-half-refund-success';
  const isProviderDeclined = transaction.attributes.lastTransition == 'transition/provider-decline' || transaction.attributes.lastTransition == 'transition/provider-decline-off-session';
  const isPartialRefundAmount = transaction?.attributes?.metadata?.partialRefundAmount;

  const isPriceAdjust = lineItems.find(item => item.code === LINE_ITEM_PRICE_ADJUSTMENT && !item.reversal);



  /**
   * OrderBreakdown contains different line items:
   *
   * LineItemBookingPeriod: prints booking start and booking end types. Prop dateType
   * determines if the date and time or only the date is shown
   *
   * LineItemShippingFeeMaybe: prints the shipping fee (combining additional fee of
   * additional items into it).
   *
   * LineItemShippingFeeRefundMaybe: prints the amount of refunded shipping fee
   *
   * LineItemBasePriceMaybe: prints the base price calculation for the listing, e.g.
   * "$150.00 * 2 nights $300"
   *
   * LineItemUnknownItemsMaybe: prints the line items that are unknown. In ideal case there
   * should not be unknown line items. If you are using custom pricing, you should create
   * new custom line items if you need them.
   *
   * LineItemSubTotalMaybe: prints subtotal of line items before possible
   * commission or refunds
   *
   * LineItemRefundMaybe: prints the amount of refund
   *
   * LineItemCustomerCommissionMaybe: prints the amount of customer commission
   * The default transaction process used by this template doesn't include the customer commission.
   *
   * LineItemCustomerCommissionRefundMaybe: prints the amount of refunded customer commission
   *
   * LineItemProviderCommissionMaybe: prints the amount of provider commission
   *
   * LineItemProviderCommissionRefundMaybe: prints the amount of refunded provider commission
   *
   * LineItemTotalPrice: prints total price of the transaction
   *
   */

  if (listingType == 'package') {
    return (
      <div className={classes}>
        <LineItemBookingPeriod
          booking={booking}
          code={lineItemUnitType}
          dateType={dateType}
          timeZone={timeZone}
          listingType={listingType}
        />
        <LineItemUnknownItemsMaybe lineItems={lineItems} isProvider={isProvider} intl={intl} />

        <LineItemTravelChargesMayBe
          lineItems={lineItems}
          intl={intl}
          distance={distance}
          setDistance={setDistance}
        />

        <LineItemDiscountMaybe lineItems={lineItems} isProvider={isProvider} intl={intl} />

        {isPriceAdjust ?
          <LineItemPriceAdjustmentMaybe
            lineItems={lineItems}
            isProvider={isProvider}
            intl={intl}
            customerPercentage={customerPercentage}
            marketplaceCurrency={currency}
            commissions={commissions}
          />
          : null}

        <LineItemProviderCommissionMaybe
          lineItems={lineItems}
          isProvider={isProvider}
          marketplaceName={marketplaceName}
          intl={intl}
          listingType={listingType}
        />
        <LineItemCustomerTotalPriceMaybe
          lineItems={lineItems}
          isCustomer={isCustomer}
          marketplaceName={marketplaceName}
          currency={currency}
          intl={intl}
          listingType={listingType}
          isHalfRefund={isHalfRefund}
          isProviderDeclined={isProviderDeclined}
        />

        {isHalfRefund && isProvider ? null :
          <LineItemExternalPaymentMaybe
            lineItems={lineItems}
            isCustomer={isCustomer}
            marketplaceName={marketplaceName}
            intl={intl}
            isProviderDeclined={isProviderDeclined}
            currency={currency}
          />
        }
        <LineItemTotalPrice
          transaction={transaction}
          isProvider={isProvider}
          intl={intl}
          currency={currency}
          lineItems={lineItems}
          isFullRefund={isFullRefund}
          isHalfRefund={isHalfRefund}
          isPartialRefundAmount={isPartialRefundAmount}
          isProviderDeclined={isProviderDeclined}
        />
      </div>
    )
  }

  return (
    <div className={classes}>
      <LineItemBookingPeriod
        booking={booking}
        code={lineItemUnitType}
        dateType={dateType}
        timeZone={timeZone}
      />

      <LineItemBasePriceMaybe
        lineItems={lineItems}
        code={lineItemUnitType}
        intl={intl}
        duration={duration}
        customerUnitPrice={unitPrice}
        isCustomer={isCustomer}
        marketplaceCurrency={currency}
        listingType={listingType}
      />
      <LineItemShippingFeeMaybe lineItems={lineItems} intl={intl} />
      <LineItemPickupFeeMaybe lineItems={lineItems} intl={intl} />
      <LineItemUnknownItemsMaybe lineItems={lineItems} isProvider={isProvider} intl={intl} />

      {/* <LineItemSubTotalMaybe
        lineItems={lineItems}
        code={lineItemUnitType}
        userRole={userRole}
        intl={intl}
        customerUnitPrice={unitPrice} 
        isCustomer={isCustomer}
        marketplaceCurrency={currency}
      /> */}


      {/* <LineItemCustomerCommissionMaybe
        lineItems={lineItems}
        isCustomer={isCustomer}
        marketplaceName={marketplaceName}
        intl={intl}
      /> */}
      {/* <LineItemCustomerCommissionRefundMaybe
        lineItems={lineItems}
        isCustomer={isCustomer}
        marketplaceName={marketplaceName}
        intl={intl}
      /> */}

      {/* Discount */}
      <LineItemDiscountMaybe lineItems={lineItems} isProvider={isProvider} intl={intl} />

      <LineItemTravelChargesMayBe
        lineItems={lineItems}
        intl={intl}
        distance={distance}
        isProvider={isProvider}
        setDistance={setDistance}
      />

      {isPriceAdjust ?
        <LineItemPriceAdjustmentMaybe
          lineItems={lineItems}
          isProvider={isProvider}
          intl={intl}
          customerPercentage={customerPercentage}
          marketplaceCurrency={currency}
          commissions={commissions}
        />
        : null}

      <LineItemProviderCommissionMaybe
        lineItems={lineItems}
        isProvider={isProvider}
        marketplaceName={marketplaceName}
        intl={intl}
      />

      {isProviderDeclined ? null :
        <LineItemProviderCommissionRefundMaybe
          lineItems={lineItems}
          isProvider={isProvider}
          marketplaceName={marketplaceName}
          intl={intl}
        />
      }



      <LineItemCustomerTotalPriceMaybe
        lineItems={lineItems}
        isCustomer={isCustomer}
        marketplaceName={marketplaceName}
        currency={currency}
        intl={intl}
        isHalfRefund={isHalfRefund}
        isProviderDeclined={isProviderDeclined}
      />

      {/*External payment */}
      {isHalfRefund && isProvider ? null :
        <LineItemExternalPaymentMaybe
          lineItems={lineItems}
          isCustomer={isCustomer}
          marketplaceName={marketplaceName}
          intl={intl}
          isHalfRefund={isHalfRefund}
          isFullRefund={isFullRefund}
          currency={currency}
          isProviderDeclined={isProviderDeclined}
        />
      }

      {/*You will make  */}
      <LineItemTotalPrice
        transaction={transaction}
        isProvider={isProvider}
        intl={intl}
        currency={currency}
        lineItems={lineItems}
        isFullRefund={isFullRefund}
        isHalfRefund={isHalfRefund}
        isPartialRefundAmount={isPartialRefundAmount}
        isProviderDeclined={isProviderDeclined}
      />

      {isProviderDeclined ? null :
        <LineItemRefundMaybe lineItems={lineItems} intl={intl} marketplaceCurrency={currency} isFullRefund={isFullRefund} isProvider={isProvider} />
      }
      {(isFullRefund || (isPartialRefundAmount && isCustomer)) ?
        <LineItemTotalAfterRefund
          transaction={transaction}
          isProvider={isProvider}
          intl={intl}
          currency={currency}
          lineItems={lineItems}
          isFullRefund={isFullRefund}
          isHalfRefund={isHalfRefund}
          isPartialRefundAmount={isPartialRefundAmount}
        />
        : null}

      {/* {hasCommissionLineItem ? (
        <span className={css.feeInfo}>
          <FormattedMessage id="OrderBreakdown.commissionFeeNote" />
        </span>
      ) : null} */}
    </div>
  );


};

OrderBreakdownComponent.defaultProps = {
  rootClassName: null,
  className: null,
  booking: null,
  dateType: null,
};

OrderBreakdownComponent.propTypes = {
  rootClassName: string,
  className: string,

  marketplaceName: string.isRequired,
  userRole: oneOf(['customer', 'provider']).isRequired,
  transaction: propTypes.transaction.isRequired,
  booking: propTypes.booking,
  dateType: propTypes.dateType,

  // from injectIntl
  intl: intlShape.isRequired,
};

const OrderBreakdown = injectIntl(OrderBreakdownComponent);

OrderBreakdown.displayName = 'OrderBreakdown';

export default OrderBreakdown;
