import React, { useEffect, useState, useRef } from 'react';
import { bool, node, string } from 'prop-types';
import classNames from 'classnames';

import LayoutComposer from '../LayoutComposer';
import LayoutWrapperAccountSettingsSideNav from './LayoutWrapperAccountSettingsSideNav';

import css from './LayoutSideNavigation.module.css';
import {
  AppstoreOutlined,
  UnorderedListOutlined,
  InboxOutlined,
  UserOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import Inbox from './img/inbox.png';
import Dashboard from './img/dashboard.png';
import Listing from './img/listing.png';
import RequestedQuotes from './img/requested-quotes.png';
import SubmittedQuotes from './img/submitted-quotes.png';
import Profile from './img/profile.png';
import Settings from './img/settings.png';
import Logout from './img/logout.png';
import { Button, Menu, Dropdown, Typography } from 'antd';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { createResourceLocatorString } from '../../../util/routes';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';



// Commonly used layout
const LayoutSideNavigation = props => {

  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();

  const [collapsed, setCollapsed] = useState(false);
  const [selectedTab, setSelectedTab] = useState(null);
  const [openTab, setOpenTab] = useState(null);

  
  const MAX_MOBILE_SCREEN_WIDTH = 1024;
  const hasMatchMedia = typeof window !== 'undefined' && window?.matchMedia;
  const isMobileLayout = hasMatchMedia
    ? window.matchMedia(`(max-width: ${MAX_MOBILE_SCREEN_WIDTH}px)`)?.matches
    : true;

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const {
    className,
    rootClassName,
    containerClassName,
    mainColumnClassName,
    sideNavClassName,
    children,
    topbar: topbarContent,
    footer: footerContent,
    sideNav: sideNavContent,
    useAccountSettingsNav,
    currentPage,
    isAdmin,
    isProvider,
    isProfilePage,
    ...rest
  } = props;

  // const items = [
  //   isProvider ? {
  //     key: '1',
  //     icon: <img src={Dashboard} />,
  //     label: 'Dashboard',
  //     children: [
  //       {
  //         key: '100',
  //         label: 'Bookings',
  //         linkProps: {
  //           name: 'DashboardPage',
  //           params: { tab: 'bookings' },
  //         }
  //       },
  //       {
  //         key: '101',
  //         label: 'Discount',
  //         linkProps: {
  //           name: 'DashboardPage',
  //           params: { tab: 'discount' },
  //         }
  //       },
  //     ],
  //   } : {},
  //   {
  //     key: '2',
  //     icon: <img src={Inbox} />,
  //     label: 'Inbox',
  //     children: [
  //       {
  //         key: '200',
  //         label: 'As a customer',
  //         linkProps: {
  //           name: 'InboxPage',
  //           params: { tab: 'orders' },
  //         }
  //       },
  //       {
  //         key: '201',
  //         label: 'As a service provider',
  //         linkProps: {
  //           name: 'InboxPage',
  //           params: { tab: 'sales' },
  //         }
  //       },
  //     ],
  //   },
  //   isProvider ? {
  //     key: '3',
  //     icon: <img src={Listing} />,
  //     label: 'My Listings',
  //     linkProps: {
  //       name: 'ManageListingsPage',
  //       params: {},
  //     }
  //   } : {},,
  //   {
  //     key: '4',
  //     label: 'Profile Settings',
  //     icon: <img src={Profile} />,
  //     linkProps: {
  //       name: 'ProfileSettingsPage',
  //       params: {},
  //     }
  //   },
  //   {
  //     key: '5',
  //     icon: <img src={Settings} />,
  //     label: 'Account Settings',
  //     children: [
  //       {
  //         key: '500',
  //         label: 'Contact details',
  //         linkProps: {
  //           name: 'ContactDetailsPage',
  //           params: {},
  //         }
  //       },
  //       {
  //         key: '501',
  //         label: 'Password',
  //         linkProps: {
  //           name: 'PasswordChangePage',
  //           params: {},
  //         }
  //       },
  //       {
  //         key: '502',
  //         label: 'Payout details',
  //         linkProps: {
  //           name: 'StripePayoutPage',
  //           params: {},
  //         }
  //       },
  //       {
  //         key: '503',
  //         label: 'Payment methods',
  //         linkProps: {
  //           name: 'PaymentMethodsPage',
  //           params: {},
  //         }
  //       },
  //     ],
  //   },
  // ];

  // if (isAdmin) {

  //   items
  //     .find(x => x?.label == 'Dashboard')?.children.push(
  //       {
  //         key: '102',
  //         label: 'Configuration',
  //         linkProps: {
  //           name: 'SettingPage',
  //           params: {},
  //         },
  //       },
  //       {
  //         key: '103',
  //         label: 'Listings',
  //         linkProps: {
  //           name: 'AdminListingPage',
  //           params: {},
  //         },
  //       },
  //       {
  //         key: '104',
  //         label: 'Users',
  //         linkProps: {
  //           name: 'AdminUserPage',
  //           params: {},
  //         },
  //       }
  //     );

  //   if (!isProvider) {
  //     items[0] = {
  //       key: '1',
  //       icon: <img src={Inbox} />,
  //       label: 'Dashboard',
  //       children: [
  //         {
  //           key: '102',
  //           label: 'Configuration',
  //           linkProps: {
  //             name: 'SettingPage',
  //             params: {},
  //           },
  //         },
  //         {
  //           key: '103',
  //           label: 'Listings',
  //           linkProps: {
  //             name: 'AdminListingPage',
  //             params: {},
  //           },
  //         },
  //         {
  //           key: '104',
  //           label: 'Users',
  //           linkProps: {
  //             name: 'AdminUserPage',
  //             params: {},
  //           },
  //         }
  //       ],
  //     }
  //   }
  // }

  const providerAdminItems = [
    {
      key: '1',
      icon: <img src={Dashboard} />,
      label: 'Dashboard',
      children: [
        {
          key: '100',
          label: 'Bookings',
          linkProps: {
            name: 'DashboardPage',
            params: { tab: 'bookings' },
          }
        },
        {
          key: '101',
          label: 'Discount',
          linkProps: {
            name: 'DashboardPage',
            params: { tab: 'discount' },
          }
        },
        {
          key: '102',
          label: 'Configuration',
          linkProps: {
            name: 'SettingPage',
            params: {},
          },
        },
        {
          key: '103',
          label: 'Listings',
          linkProps: {
            name: 'AdminListingPage',
            params: {},
          },
        },
        {
          key: '104',
          label: 'Users',
          linkProps: {
            name: 'AdminUserPage',
            params: {},
          },
        },
      ],
    },
    {
      key: '2',
      icon: <img src={Inbox} />,
      label: 'Inbox',
      children: [
        {
          key: '200',
          label: 'As a customer',
          linkProps: {
            name: 'InboxPage',
            params: { tab: 'orders' },
          }
        },
        {
          key: '201',
          label: 'As a service provider',
          linkProps: {
            name: 'InboxPage',
            params: { tab: 'sales' },
          }
        },
      ],
    },
    {
      key: '3',
      icon: <img src={Listing} />,
      label: 'My Listings',
      linkProps: {
        name: 'ManageListingsPage',
        params: {},
      },
    },
    {
      key: '4',
      label: 'Profile Settings',
      icon: <img src={Profile} />,
      linkProps: {
        name: 'ProfileSettingsPage',
        params: {},
      },
    },
    {
      key: '5',
      icon: <img src={Settings} />,
      label: 'Account Settings',
      children: [
        {
          key: '500',
          label: 'Contact details',
          linkProps: {
            name: 'ContactDetailsPage',
            params: {},
          }
        },
        {
          key: '501',
          label: 'Password',
          linkProps: {
            name: 'PasswordChangePage',
            params: {},
          }
        },
        {
          key: '502',
          label: 'Payout details',
          linkProps: {
            name: 'StripePayoutPage',
            params: {},
          }
        },
        {
          key: '503',
          label: 'Payment methods',
          linkProps: {
            name: 'PaymentMethodsPage',
            params: {},
          }
        },
      ],
    },
  ];

  const customerAdminItems = [
    {
      key: '1',
      icon: <img src={Inbox} />,
      label: 'Dashboard',
      children: [
        {
          key: '102',
          label: 'Configuration',
          linkProps: {
            name: 'SettingPage',
            params: {},
          },
        },
        {
          key: '103',
          label: 'Listings',
          linkProps: {
            name: 'AdminListingPage',
            params: {},
          },
        },
        {
          key: '104',
          label: 'Users',
          linkProps: {
            name: 'AdminUserPage',
            params: {},
          },
        },
      ],
    },
    {
      key: '2',
      icon: <img src={Inbox} />,
      label: 'Inbox',
      children: [
        {
          key: '200',
          label: 'As a customer',
          linkProps: {
            name: 'InboxPage',
            params: { tab: 'orders' },
          }
        },
        {
          key: '201',
          label: 'As a service provider',
          linkProps: {
            name: 'InboxPage',
            params: { tab: 'sales' },
          }
        },
      ],
    },
    {
      key: '4',
      label: 'Profile Settings',
      icon: <img src={Profile} />,
      linkProps: {
        name: 'ProfileSettingsPage',
        params: {},
      },
    },
    {
      key: '5',
      icon: <img src={Settings} />,
      label: 'Account Settings',
      children: [
        {
          key: '500',
          label: 'Contact details',
          linkProps: {
            name: 'ContactDetailsPage',
            params: {},
          }
        },
        {
          key: '501',
          label: 'Password',
          linkProps: {
            name: 'PasswordChangePage',
            params: {},
          }
        },
        {
          key: '503',
          label: 'Payment methods',
          linkProps: {
            name: 'PaymentMethodsPage',
            params: {},
          }
        },
      ],
    },
  ];

  const customerItems = [
    {
      key: '2',
      icon: <img src={Inbox} />,
      label: 'Inbox',
      children: [
        {
          key: '200',
          label: 'As a customer',
          linkProps: {
            name: 'InboxPage',
            params: { tab: 'orders' },
          }
        },
        {
          key: '201',
          label: 'As a service provider',
          linkProps: {
            name: 'InboxPage',
            params: { tab: 'sales' },
          }
        },
      ],
    },
    {
      key: '4',
      label: 'Profile Settings',
      icon: <img src={Profile} />,
      linkProps: {
        name: 'ProfileSettingsPage',
        params: {},
      },
    },
    {
      key: '5',
      icon: <img src={Settings} />,
      label: 'Account Settings',
      children: [
        {
          key: '500',
          label: 'Contact details',
          linkProps: {
            name: 'ContactDetailsPage',
            params: {},
          }
        },
        {
          key: '501',
          label: 'Password',
          linkProps: {
            name: 'PasswordChangePage',
            params: {},
          }
        },
        {
          key: '503',
          label: 'Payment methods',
          linkProps: {
            name: 'PaymentMethodsPage',
            params: {},
          }
        },
      ],
    },
  ];

  const providerItems = [
    {
      key: '1',
      icon: <img src={Dashboard} />,
      label: 'Dashboard',
      children: [
        {
          key: '100',
          label: 'Bookings',
          linkProps: {
            name: 'DashboardPage',
            params: { tab: 'bookings' },
          }
        },
        {
          key: '101',
          label: 'Discount',
          linkProps: {
            name: 'DashboardPage',
            params: { tab: 'discount' },
          }
        },
      ],
    },
    {
      key: '2',
      icon: <img src={Inbox} />,
      label: 'Inbox',
      children: [
        {
          key: '200',
          label: 'As a customer',
          linkProps: {
            name: 'InboxPage',
            params: { tab: 'orders' },
          }
        },
        {
          key: '201',
          label: 'As a service provider',
          linkProps: {
            name: 'InboxPage',
            params: { tab: 'sales' },
          }
        },
      ],
    },
    {
      key: '3',
      icon: <img src={Listing} />,
      label: 'My Listings',
      linkProps: {
        name: 'ManageListingsPage',
        params: {},
      },
    },
    {
      key: '4',
      label: 'Profile Settings',
      icon: <img src={Profile} />,
      linkProps: {
        name: 'ProfileSettingsPage',
        params: {},
      },
    },
    {
      key: '5',
      icon: <img src={Settings} />,
      label: 'Account Settings',
      children: [
        {
          key: '500',
          label: 'Contact details',
          linkProps: {
            name: 'ContactDetailsPage',
            params: {},
          }
        },
        {
          key: '501',
          label: 'Password',
          linkProps: {
            name: 'PasswordChangePage',
            params: {},
          }
        },
        {
          key: '502',
          label: 'Payout details',
          linkProps: {
            name: 'StripePayoutPage',
            params: {},
          }
        },
        {
          key: '503',
          label: 'Payment methods',
          linkProps: {
            name: 'PaymentMethodsPage',
            params: {},
          }
        },
      ],
    },
  ];

  let items = [];

  if( isProvider && isAdmin) {
    items = providerAdminItems;
  } else if( !isProvider && isAdmin) {
    items = customerAdminItems;
  } else if( isProvider && !isAdmin) {
    items = providerItems;
  } else {
    items = customerItems;
  }

  const classes = classNames(rootClassName || css.root, className);
  const containerClasses = containerClassName || css.container;

  // TODO: since responsiveAreas are still experimental,
  //       we don't separate "aside" through layoutComposer
  const layoutAreas = `
    topbar
    main
    footer
  `;

  useEffect(() => {

    const currentPage = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
    
    if (isMobileLayout) {
      switch (currentPage) {
        case 'bookings':
          localStorage.setItem('selectedTab', 100);
          localStorage.setItem('openTab', 100);
          break;
        case 'discount':
          localStorage.setItem('selectedTab', 101);
          localStorage.setItem('openTab', 100);
          break;
        case 'setting':
          localStorage.setItem('selectedTab', 102);
          localStorage.setItem('openTab', 102);
          break;
        case 'listing':
          localStorage.setItem('selectedTab', 103);
          localStorage.setItem('openTab', 103);
          break;
        case 'users':
          localStorage.setItem('selectedTab', 104);
          localStorage.setItem('openTab', 104);
          break;
        case 'orders':
          localStorage.setItem('selectedTab', 200);
          localStorage.setItem('openTab', 200);
          break;
        case 'sales':
          localStorage.setItem('selectedTab', 201);
          localStorage.setItem('openTab', 201);
          break;
        case 'listings':
          localStorage.setItem('selectedTab', 3);
          localStorage.setItem('openTab', 3);
          break;
        case 'profile-settings':
          localStorage.setItem('selectedTab', 4);
          localStorage.setItem('openTab', 4);
          break;
        case 'contact-details':
          localStorage.setItem('selectedTab', 500);
          localStorage.setItem('openTab', 500);
          break;
        case 'change-password':
          localStorage.setItem('selectedTab', 501);
          localStorage.setItem('openTab', 501);
          break;
        case 'payments':
          localStorage.setItem('selectedTab', 502);
          localStorage.setItem('openTab', 502);
          break;
        case 'payment-methods':
          localStorage.setItem('selectedTab', 503);
          localStorage.setItem('openTab', 503);
          break;
        default:
          break;
      }
    } else {
      switch (currentPage) {
        case 'bookings':
          localStorage.setItem('selectedTab', 100);
          localStorage.setItem('openTab', 1);
          break;
        case 'discount':
          localStorage.setItem('selectedTab', 101);
          localStorage.setItem('openTab', 1);
          break;
        case 'setting':
          localStorage.setItem('selectedTab', 102);
          localStorage.setItem('openTab', 1);
          break;
        case 'listing':
          localStorage.setItem('selectedTab', 103);
          localStorage.setItem('openTab', 1);
          break;
        case 'users':
          localStorage.setItem('selectedTab', 104);
          localStorage.setItem('openTab', 1);
          break;
        case 'orders':
          localStorage.setItem('selectedTab', 200);
          localStorage.setItem('openTab', 2);
          break;
        case 'sales':
          localStorage.setItem('selectedTab', 201);
          localStorage.setItem('openTab', 2);
          break;
        case 'listings':
          localStorage.setItem('selectedTab', 3);
          localStorage.setItem('openTab', 3);
          break;
        case 'profile-settings':
          localStorage.setItem('selectedTab', 4);
          localStorage.setItem('openTab', 4);
          break;
        case 'contact-details':
          localStorage.setItem('selectedTab', 500);
          localStorage.setItem('openTab', 5);
          break;
        case 'change-password':
          localStorage.setItem('selectedTab', 501);
          localStorage.setItem('openTab', 5);
          break;
        case 'payments':
          localStorage.setItem('selectedTab', 502);
          localStorage.setItem('openTab', 5);
          break;
        case 'payment-methods':
          localStorage.setItem('selectedTab', 503);
          localStorage.setItem('openTab', 5);
          break;
        default:
          break;
      }
    }

    const storedTab = localStorage.getItem('selectedTab');
    if (storedTab) {
      setSelectedTab(storedTab);
    }

    const openTab = localStorage.getItem('openTab');
    if (openTab) {
      setOpenTab(openTab);
    }

  }, []);

  const menuOnOpen = (values) => {
    if(values?.length != 0) {
      setOpenTab(values[1]);
      localStorage.setItem('openTab', values[1] || values[0]);
    }
    setOpenKeys(values);
  }

  const menuOnClick = (values) => {

    setSelectedTab(values.key);
    localStorage.setItem('selectedTab', values.key);
    
    setOpenTab(isMobileLayout? (values.key || (parseInt(values.key) / 100)?.toFixed(0)): (parseInt(values.key) / 100)?.toFixed(0));
    if(isMobileLayout) {
      localStorage.setItem('openTab', values.key || (parseInt(values.key) / 100)?.toFixed(0) );
    } else {
      localStorage.setItem('openTab', (parseInt(values.key) / 100)?.toFixed(0) || values.key);
    }
    


    history.push(
      createResourceLocatorString(
        values?.item?.props?.linkProps?.name,
        routeConfiguration,
        values?.item?.props?.linkProps?.params,
        {}
      )
    );
    setOpenKeys([]);
  }



    const [openKeys, setOpenKeys] = useState([openTab]); // Control the open state
    const menuRef = useRef(null); // Reference to the Menu
  
    // Function to handle clicks outside the menu
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenKeys([]); // Close the menu when clicking outside
      }
    };
  
    useEffect(() => {
      // Add event listener for clicks outside
      document.addEventListener('mousedown', handleClickOutside);
  
      // Cleanup the event listener on component unmount
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

  return (
    <LayoutComposer areas={layoutAreas} className={classes} {...rest}>
      {layoutProps => {
        const { Topbar, Main, Footer } = layoutProps;
        return (
          <>
            <Topbar as="header" className={css.topbar}>
              {topbarContent}
            </Topbar>

            <Main as="div" className={containerClasses}>
              <aside className={classNames(css.sideNav, sideNavClassName)}>
                {useAccountSettingsNav ? (
                  <LayoutWrapperAccountSettingsSideNav currentPage={currentPage} />
                ) : null}
                {sideNavContent}

                {isProfilePage ? null : (
                  <div className={css.menuAllContainer}>
                    <Button type="primary" onClick={toggleCollapsed} className={css.toggleBtn}>
                      {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    </Button>
                    <div className={css.dashMenu}>
                      <Menu
                        defaultSelectedKeys={[openTab]}
                        defaultOpenKeys={[openTab]}
                        openKeys={[openTab]}
                        selectedKeys={[selectedTab]}
                        mode="inline"
                        theme="dark"
                        inlineCollapsed={collapsed}
                        items={items}
                        onOpenChange={menuOnOpen}
                        onClick={menuOnClick}
                      />
                    </div>
                    <div className={css.dashMobileMenu}>
                      {/*<Menu 
                        onClick={menuOnClick} 
                        selectedKeys={[selectedTab]} 
                        mode="horizontal" 
                        onOpenChange={menuOnOpen}
                        items={items} 
                        defaultSelectedKeys={[openTab]}
                        defaultOpenKeys={[openTab]}
                        openKeys={[openTab]}
                      />*/}

                      <div
                        ref={menuRef}
                       
                      >
                        <Menu
                          onClick={menuOnClick}
                          selectedKeys={[selectedTab]}
                          mode="horizontal"
                          onOpenChange={menuOnOpen}
                          items={items}
                          defaultSelectedKeys={[openTab]}
                          defaultOpenKeys={[openTab]}
                          openKeys={openKeys} // Bind the state-controlled open keys
                          className={
                            isProvider && isAdmin
                              ? css.mobileMenu5
                              : !isProvider && isAdmin
                              ? css.mobileMenu4
                              : isProvider && !isAdmin
                              ? css.mobileMenu5
                              : css.mobileMenu3
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
              </aside>
              <main className={classNames(css.main, mainColumnClassName)}>
                <div className={css.mainContainer}>{children}</div>
              </main>
            </Main>
            <Footer>{footerContent}</Footer>
          </>
        );
      }}
    </LayoutComposer>
  );
};

LayoutSideNavigation.displayName = 'LayoutSideNavigation';

LayoutSideNavigation.defaultProps = {
  className: null,
  rootClassName: null,
  sideNav: null,
  footer: null,
  useAccountSettingsNav: false,
  currentPage: null,
};

LayoutSideNavigation.propTypes = {
  className: string,
  rootClassName: string,
  children: node.isRequired,
  topbar: node.isRequired,
  sideNav: node,
  footer: node,
  useAccountSettingsNav: bool,
  currentPage: string,
};

export default LayoutSideNavigation;
