import React from 'react';
import { FormattedMessage, FormattedDate } from '../../util/reactIntl';
import { LINE_ITEM_NIGHT, DATE_TYPE_DATE, LINE_ITEM_HOUR, propTypes } from '../../util/types';
import { subtractTime } from '../../util/dates';

import css from './OrderBreakdown.module.css';

const BookingPeriod = props => {
  const { startDate, endDate, dateType, timeZone, listingType } = props;
  const timeZoneMaybe = timeZone ? { timeZone } : null;

  const timeFormatOptions =
    dateType === DATE_TYPE_DATE
      ? {
          weekday: 'long',
        }
      : {
          weekday: 'short',
          hour: 'numeric',
          minute: 'numeric',
        };

  const dateFormatOptions = {
    month: 'short',
    day: 'numeric',
  };

  const startDateYear = new Date(startDate)?.getFullYear();
  const endDateYear = new Date(endDate)?.getFullYear();

  if(listingType == 'package'){
    return (
      <>
          <div className={css.bookingPeriodSectionLeftPackage}>
            <div className={css.dayLabelPackage}>
              <FormattedMessage id="OrderBreakdown.readyTime" />
            </div>
            <div className={css.readyTime}>
            <div className={css.dayInfoPackage}>
              <FormattedDate value={startDate} {...timeFormatOptions} {...timeZoneMaybe} />
            </div>
            <div className={css.itemLabelPackage}>
              <FormattedDate value={startDate} {...dateFormatOptions} {...timeZoneMaybe} /> {startDateYear}
            </div>
            </div>
          </div>
      </>
    );
  }

  return (
    <>
      <div className={css.bookingPeriod}>
        <div className={css.bookingPeriodSectionLeft}>
          <div className={css.dayLabel}>
            <FormattedMessage id="OrderBreakdown.bookingStart" />
          </div>
          <div className={css.dayInfo}>
            <FormattedDate value={startDate} {...timeFormatOptions} {...timeZoneMaybe} /> 
          </div>
          <div className={css.itemLabel}>
            <FormattedDate value={startDate} {...dateFormatOptions} {...timeZoneMaybe} /> {startDateYear}
          </div>
        </div>

        <div className={css.bookingPeriodSectionRight}>
          <div className={css.dayLabel}>
            <FormattedMessage id="OrderBreakdown.bookingEnd" />
          </div>
          <div className={css.dayInfo}>
            <FormattedDate value={endDate} {...timeFormatOptions} {...timeZoneMaybe} />
          </div>
          <div className={css.itemLabel}>
            <FormattedDate value={endDate} {...dateFormatOptions} {...timeZoneMaybe} /> {endDateYear}
          </div>
        </div>
      </div>
    </>
  );
};

const LineItemBookingPeriod = props => {
  const { booking, code, dateType, timeZone, listingType } = props;

  if (!booking) {
    return null;
  }
  
  let lineitemCode = code;
  let datetype = dateType;

  if(listingType == 'package'){
    lineitemCode = "line-item/hour";
    datetype = 'dateTime'
  }
  // Attributes: displayStart and displayEnd can be used to differentiate shown time range
  // from actual start and end times used for availability reservation. It can help in situations
  // where there are preparation time needed between bookings.
  // Read more: https://www.sharetribe.com/api-reference/marketplace.html#bookings
  const { start, end, displayStart, displayEnd } = booking.attributes;
  const localStartDate = displayStart || start;
  const localEndDateRaw = displayEnd || end;

  const isNightly = lineitemCode === LINE_ITEM_NIGHT;
  const isHour = lineitemCode === LINE_ITEM_HOUR;
  const endDay = isNightly || isHour ? localEndDateRaw : subtractTime(localEndDateRaw, 1, 'days');

  return (
    <>
      <div className={css.lineItem}>
        <BookingPeriod
          startDate={localStartDate}
          endDate={endDay}
          dateType={datetype}
          timeZone={timeZone}
          listingType={listingType}
        />
      </div>
      <hr className={css.totalDivider} />
    </>
  );
};
LineItemBookingPeriod.defaultProps = { booking: null, dateType: null };

LineItemBookingPeriod.propTypes = {
  booking: propTypes.booking,
  dateType: propTypes.dateType,
};

export default LineItemBookingPeriod;
