import React, { useState } from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';
import { Badge } from 'antd';
import { useConfiguration } from '../../context/configurationContext';

import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';
import {
  UserOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl, isProfilePage } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  var unitType = publicData?.unitType;
  if( listingType == 'duration' ){
    unitType = 'service'
  }
  if( listingType == 'package' ){
    unitType = 'package'
  }
  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  if( listingType == 'package' ){
    return (
      <div className={css.price}>
      <span className={css.perUnit}>From</span>
        <div className={css.priceValue} title={priceTitle}>
          {formattedPrice}
        </div>
        {isBookable ? (
          <div className={isProfilePage ? css.perUnitProfilePage : css.perUnit}>
            {/* <ClockCircleOutlined className={css.perUnitIcon} /> */}
            {isProfilePage ? <br/> : null}
            <FormattedMessage id="ListingCard.perUnit" values={{ unitType }} /> &nbsp;
          </div>
        ) : null}
      </div>
    );
  }
  return (
    <div className={css.price}>
      <span className={css.perUnit}>From</span>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={isProfilePage ? css.perUnitProfilePage : css.perUnit}>
          {/* <ClockCircleOutlined className={css.perUnitIcon} /> */}
          {isProfilePage ? <br/> : null}
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType }} />
        </div>
      ) : null}
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    commissions,
    currentUserId,
    isProfilePage,
  } = props;
  const [commissionPrice, setCommissionPrice] = useState(0);
  const classes = classNames(rootClassName || css.listingCard, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  let { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
   
  const [isDiscountAvailable, setDiscountAvailable] = useState(false);
  const [discountValue, setDiscountValue] = useState(false);

  
  // Check if discountDetails exists
  const discountDetails = currentListing?.attributes?.publicData?.discountDetails;
  if (discountDetails) {
    // Check if currentListing.id.uuid exists in listIds
  
    if (discountDetails && !isDiscountAvailable) {
      let currentDate = new Date();

      currentDate = currentDate.toISOString();
      if (new Date(currentDate) <= new Date(discountDetails.serviceEndDate)) {
        setDiscountAvailable(true);
        setDiscountValue(discountDetails.discountType == 'percentage' ? `${discountDetails.discountValue}% off` : `$${discountDetails.discountValue} off`)
      }
    }   
  }

  
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

    const isOwnListing = author.id.uuid == currentUserId;
    
    if (isOwnListing) {     
      const ownListingPrice = new Money(price?.amount, price?.currency);
      if (commissionPrice.amount !== ownListingPrice.amount) {
        setCommissionPrice(ownListingPrice);
      }
    }
        
    if (commissions){ 
      if (!isOwnListing && commissions.customerCommission && price) {
        const customerCommissionPrice = (price.amount * commissions.customerCommission.percentage) / 100;
        const updatedPrice = new Money(price.amount + customerCommissionPrice, price?.currency);
        if (commissionPrice.amount !== updatedPrice.amount) {
          setCommissionPrice(updatedPrice);
        }
      }  
    }

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <LazyImage
          rootClassName={css.rootForImageShadow}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
      </AspectRatioWrapper>
      <div className={css.info}>
        <div className={css.mainInfo}>
          <div>
          <div className={isProfilePage ? css.titleInProfilePage : css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          </div>
          {isDiscountAvailable ? (
            
            <div className={css.discountLabel}>
              <span className={css.discountTag}>                
                <Badge.Ribbon text={discountValue}></Badge.Ribbon>
              </span>
            </div>
          ) : null}
        </div>     
          {showAuthorInfo ? (
            <div className={css.authorInfo}>
              <UserOutlined /><FormattedMessage id="ListingCard.author" values={{ authorName }} />
            </div>
          ) : null}     
        <PriceMaybe price={commissionPrice} publicData={publicData} config={config} intl={intl} isProfilePage={isProfilePage}/>
       
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
