import React from 'react';
import Decimal from 'decimal.js';

import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_CUSTOMER_COMMISSION, LINE_ITEM_PROVIDER_COMMISSION, propTypes } from '../../util/types';
import { resolveLatestProcessName, getProcess } from '../../transactions/transaction';

import css from './OrderBreakdown.module.css';

import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;

const lineItemsTotal = (lineItems, marketplaceCurrency) => {
  const amount = lineItems.reduce((total, item) => {
    return total.plus(item.lineTotal.amount);
  }, new Decimal(0));
  const currency = lineItems[0] ? lineItems[0].lineTotal.currency : marketplaceCurrency;
  return new Money(amount, currency);
};

const LineItemTotalPrice = props => {
  const { transaction, isProvider, intl, lineItems, currency, isFullRefund, isHalfRefund, isPartialRefundAmount } = props;
  const processName = resolveLatestProcessName(transaction?.attributes?.processName);
  if (!processName) {
    return null;
  }
  
  const process = getProcess(processName);
  const isCompleted = process.isCompleted(transaction?.attributes?.lastTransition);
  const isRefunded = process.isRefunded(transaction?.attributes?.lastTransition);

  let providerTotalMessageId = 'OrderBreakdown.providerTotalDefault';
  if (isCompleted) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalReceived';
  } else if (isRefunded) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalRefunded';
  }


  let totalLabel = isProvider ? (
    <FormattedMessage id={providerTotalMessageId} />
  ) : (
    <FormattedMessage id="OrderBreakdown.totalPrice" />
  );


  const totalPrice = isProvider
    ? transaction.attributes.payoutTotal
    : transaction.attributes.payinTotal;

  let formattedTotalPrice = formatMoney(intl, totalPrice);

  if(isFullRefund ) {

    totalLabel = <FormattedMessage id="OrderBreakdown.totalPriceFullRefund" />;

    const nonCommissionReversalLineItems = lineItems => {
      return lineItems.filter(item => !item.reversal);
    };

    const refundLineItems = nonCommissionReversalLineItems(lineItems);
    const refund = lineItemsTotal(refundLineItems, currency);

    formattedTotalPrice = refundLineItems?.length > 0 ? formatMoney(intl, refund) : null;
  } else if(isPartialRefundAmount) {


    if(isProvider) {
      formattedTotalPrice = formatMoney(intl, new Money(0, currency));
    }else {
      formattedTotalPrice = formatMoney(intl, transaction?.attributes?.payoutTotal);
    }

  }

  if(isHalfRefund && !isProvider) {
    formattedTotalPrice = formatMoney(intl, new Money(transaction?.attributes?.metadata?.depositAmount || 0, currency));
  }

  if(isFullRefund && isProvider){
    return null;
  }


  return (
    <>
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>{totalLabel}</div>
        <div className={css.totalPrice}>{formattedTotalPrice}</div>
      </div>
    </>
  );
};

LineItemTotalPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemTotalPrice;
