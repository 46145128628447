import React from 'react';
import { bool, string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';

import { LINE_ITEM_EXTERNAL_PAYMENT, propTypes } from '../../util/types';
import css from './OrderBreakdown.module.css';

const { Money } = sdkTypes;

// Validate the assumption that the commission exists and the amount
// is zero or negative.

const isValidExternalPayment = paymentLineItem => {
  return paymentLineItem.lineTotal instanceof Money && paymentLineItem.lineTotal.amount > 0;
};


const LineItemExternalPaymentMaybe = props => {
    const { lineItems, isCustomer, marketplaceName, intl, isHalfRefund, isFullRefund, isProviderDeclined, currency } = props;
    
  
    const externalPaymentLineItem = lineItems.find(
      item => item.code === LINE_ITEM_EXTERNAL_PAYMENT && !item.reversal
    );
        // If commission is passed it will be shown as a fee already reduces from the total price
  let paymentItem = null;


  // Sharetribe Web Template is using the default-booking and default-purchase transaction processes.
  // They containt the provider commissions, so by default, the providerCommissionLineItem should exist.
  // If you are not using provider commisison you might want to remove this whole component from OrderBreakdown.js file.
  // https://www.sharetribe.com/docs/concepts/transaction-process/


  if (externalPaymentLineItem) {
  
    // if (!isValidExternalPayment(externalPaymentLineItem)) {
    //   // eslint-disable-next-line no-console
    //   console.error('invalid external payment line item:', externalPaymentLineItem);
    //   throw new Error('External payment should be present and the value should be positive');
    // }

    const payment = externalPaymentLineItem.lineTotal;

    const zeroMoney = new Money(0, currency);

    const paymentAmount = isProviderDeclined ? formatMoney(intl, zeroMoney): payment ? formatMoney(intl, payment) : null;

    const formattedPayment = paymentAmount ? paymentAmount.replace(/-/g, '') : null;

    // const formattedPayment = payment ? cleanedPayment : null;


    paymentItem = (
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          {isCustomer? <FormattedMessage
            id="OrderBreakdown.externalPayment"
            values={{ marketplaceName, role: 'customer' }}
          /> : <FormattedMessage
          id="OrderBreakdown.externalPaymentProvider"
          values={{ marketplaceName, role: 'provider' }}
        /> }
        </span>
        <span className={css.itemValue}>{formattedPayment}</span>
      </div>
    );
  }

  if((isHalfRefund && isCustomer) || (isFullRefund && !isCustomer)){
    return null;
  }

  return (
    <><hr className={css.totalDivider} />
    {paymentItem}</>
  )
};
 
LineItemExternalPaymentMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  isCustomer: bool.isRequired,
  marketplaceName: string.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemExternalPaymentMaybe;
