import { storableError } from '../../util/errors';
import { approveUser, deleteFromBlob, fetchAdminListings, fetchAdminUsers } from '../../util/api';
import { denormalisedResponseEntities, updatedEntities } from '../../util/data';


// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/SettingPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/SettingPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/SettingPage/FETCH_LISTINGS_ERROR';

export const APPROVE_LISTINGS_REQUEST = 'app/SettingPage/APPROVE_LISTINGS_REQUEST';
export const APPROVE_LISTINGS_SUCCESS = 'app/SettingPage/APPROVE_LISTINGS_SUCCESS';
export const APPROVE_LISTINGS_ERROR = 'app/SettingPage/APPROVE_LISTINGS_ERROR';
// ================ Reducer ================ //

const initialState = {
  fetchInProgress: false,
  fetchSettingError: null,
  pagination: null,
  users: []
};

export default function adminUserPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return { ...state, fetchInProgress: true, users: [] };
    case FETCH_LISTINGS_SUCCESS: {
      return {
        ...state,
        fetchInProgress: false,
        users: payload,
      };
    }
    case APPROVE_LISTINGS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchSettingError: payload };
    case APPROVE_LISTINGS_REQUEST:
      return { ...state, fetchInProgress: true, users: [] };
    case APPROVE_LISTINGS_SUCCESS: {
      return {
        ...state,
        fetchInProgress: true,
        users: [],
      };
    }
    case FETCH_LISTINGS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchSettingError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchListingRequest = () => ({ type: FETCH_LISTINGS_REQUEST });
const fetchListingRequestSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: response,
});
const fetchSettingError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});
const approveUserRequest = () => ({ type: APPROVE_LISTINGS_REQUEST });
const approveUserRequestSuccess = response => ({
  type: APPROVE_LISTINGS_SUCCESS,
  payload: response,
});
const approveUserError = e => ({
  type: APPROVE_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  dispatch(fetchListingRequest());
  return fetchAdminUsers({})
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      dispatch(fetchListingRequestSuccess(entities))
    })
    .catch(e => {
      dispatch(fetchSettingError(storableError(e)));
      throw e;
    });
};

export const handleApproveUser = (params) => (dispatch, getState, sdk) => {
  dispatch(approveUserRequest());
  return approveUser({ params })
    .then(res => {
      const userId = res?.data?.data?.id;
      deleteFromBlob({ userId: userId }).then(resp => {
        dispatch(approveUserRequestSuccess());
        return true;
      })
    })
    .catch(e => {
      dispatch(approveUserError(storableError(e)));
      throw e;
    });
};