import React from 'react';
import { bool, string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_DISCOUNT, LINE_ITEM_PROVIDER_COMMISSION, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const { Money } = sdkTypes;

// Validate the assumption that the commission exists and the amount
// is zero or negative.
const isValidCommission = commissionLineItem => {
  return commissionLineItem.lineTotal instanceof Money && commissionLineItem.lineTotal.amount <= 0;
};

const LineItemProviderPartialRefundMaybe
 = props => {
  const { lineItems, isProvider, marketplaceName, intl, listingType } = props;

  let totalPrice = 0;

if(listingType == 'package'){
  const filtered = (lineItems?.filter(x => x.code.includes('line-item/package')));
  filtered?.map(item => 
    totalPrice = totalPrice + (item.unitPrice.amount * item.quantity)
  )
}

  const providerCommissionLineItem = lineItems.find(
    item => item.code === LINE_ITEM_PROVIDER_COMMISSION && !item.reversal
  );

  let p ={...providerCommissionLineItem}
  // If commission is passed it will be shown as a fee already reduces from the total price
  let commissionItem = null;

  // Sharetribe Web Template is using the default-booking and default-purchase transaction processes.
  // They containt the provider commissions, so by default, the providerCommissionLineItem should exist.
  // If you are not using provider commisison you might want to remove this whole component from OrderBreakdown.js file.
  // https://www.sharetribe.com/docs/concepts/transaction-process/
  if (isProvider && providerCommissionLineItem) {
    if (!isValidCommission(providerCommissionLineItem)) {
      // eslint-disable-next-line no-console
      console.error('invalid commission line item:', providerCommissionLineItem);
      throw new Error('Commission should be present and the value should be zero or negative');
    }

    const commission = providerCommissionLineItem.lineTotal;

    let paymentCommission = commission ? formatMoney(intl, commission) : null;

    const formattedCommission = paymentCommission ? paymentCommission.replace(/-/g, '') : null;

    commissionItem = (
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          Refund
        </span>
        <span className={css.itemValue}>{formattedCommission}</span>
      </div>
    );
  }

  return commissionItem;
};

LineItemProviderPartialRefundMaybe
.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  isProvider: bool.isRequired,
  marketplaceName: string.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemProviderPartialRefundMaybe
;
