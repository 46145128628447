import { storableError } from '../../util/errors';
import { getSettings, updateSettings } from '../../util/api';


// ================ Action types ================ //

export const FETCH_SETTINGS_REQUEST = 'app/SettingPage/FETCH_SETTINGS_REQUEST';
export const FETCH_SETTINGS_SUCCESS = 'app/SettingPage/FETCH_SETTINGS_SUCCESS';
export const FETCH_SETTINGS_ERROR = 'app/SettingPage/FETCH_SETTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchInProgress: false,
  fetchSettingError: null,
  pagination: null,
  settings: []
};

export default function settingPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_SETTINGS_REQUEST:
      return { ...state, fetchInProgress: true };
    case FETCH_SETTINGS_SUCCESS: {
      return {
        ...state,
        fetchInProgress: false,
        settings: payload,
      };
    }
    case FETCH_SETTINGS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchSettingError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchSettingRequest = () => ({ type: FETCH_SETTINGS_REQUEST });
const fetchSettingRequestSuccess = response => ({
  type: FETCH_SETTINGS_SUCCESS,
  payload: response,
});
const fetchSettingError = e => ({
  type: FETCH_SETTINGS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  dispatch(fetchSettingRequest());
  return getSettings({})
    .then(response => {
      return dispatch(fetchSettingRequestSuccess(response))
    })
    .catch(e => {
      dispatch(fetchSettingError(storableError(e)));
      throw e;
    });
};

export const editSettings = (params) => (dispatch, getState, sdk) => {
  dispatch(fetchSettingRequest());
  return updateSettings({ params })
    .then(response => {
      return dispatch(fetchSettingRequestSuccess(response))
    })
    .catch(e => {
      dispatch(fetchSettingError(storableError(e)));
      throw e;
    });
};