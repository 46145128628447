/**
 * Renders non-reversal line items that are not listed in the
 * `LINE_ITEMS` array in util/types.js
 *
 * The line items are rendered so that the line item code is formatted to human
 * readable form and the line total is printed as price.
 *
 * If you require another kind of presentation for your line items, add them to
 * the `LINE_ITEMS` array in util/types.js and create a specific line item
 * component for them that can be used in the `OrderBreakdown` component.
 */
import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { humanizeLineItemCode } from '../../util/data';
import { LINE_ITEM_PRICE_ADJUSTMENT, LINE_ITEMS, propTypes } from '../../util/types';

import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;

import css from './OrderBreakdown.module.css';

const LineItemPriceAdjustmentMaybe = props => {
  const { lineItems, isProvider, intl, customerPercentage, marketplaceCurrency, commissions } = props;

  // resolve unknown non-reversal line items
  const item = lineItems.find(item => item.code === LINE_ITEM_PRICE_ADJUSTMENT && !item.reversal);

  // const item = isProvider
  //   ? allItems.includeFor.includes('provider')
  //   : allItems.includeFor.includes('customer');

  
  const quantity = item?.quantity;
  
  const label =
          quantity && quantity > 1
          ? `${humanizeLineItemCode(item?.code)} x ${quantity}`
          : humanizeLineItemCode(item?.code);
          
          const formattedTotal = formatMoney(intl, item?.lineTotal);
          
          // const formattedTotalForCustomer = !isProvider && commissions ? formatMoney(intl, new Money((item?.lineTotal?.amount + (item?.lineTotal?.amount/commissions?.customerCommission.percentage)),marketplaceCurrency)) : formattedTotal;
          const formattedTotalForCustomer = !isProvider && commissions ? formatMoney(intl, new Money((item?.lineTotal?.amount),marketplaceCurrency)) : formattedTotal;

  return item ? (
    <React.Fragment>
          <div key={`1-item.code`} className={css.lineItem}>
            <span className={css.itemLabel}>Price adjustment</span>
            <span className={css.itemValue}>{isProvider ? formattedTotal : formattedTotalForCustomer}</span>
          </div>
    </React.Fragment>
  ) : null;
};

LineItemPriceAdjustmentMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemPriceAdjustmentMaybe;
