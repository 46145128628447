import { storableError } from '../../util/errors';
import { approveListing, fetchAdminListings, noActionListing } from '../../util/api';


// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/SettingPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/SettingPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/SettingPage/FETCH_LISTINGS_ERROR';

export const APPROVE_LISTINGS_REQUEST = 'app/SettingPage/APPROVE_LISTINGS_REQUEST';
export const APPROVE_LISTINGS_SUCCESS = 'app/SettingPage/APPROVE_LISTINGS_SUCCESS';
export const APPROVE_LISTINGS_ERROR = 'app/SettingPage/APPROVE_LISTINGS_ERROR';
// ================ Reducer ================ //

const initialState = {
  fetchInProgress: false,
  fetchSettingError: null,
  pagination: null,
  listing: []
};

export default function adminListingPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return { ...state, fetchInProgress: true, listing: [] };
    case FETCH_LISTINGS_SUCCESS: {
      return {
        ...state,
        fetchInProgress: false,
        listing: payload,
      };
    }
    case APPROVE_LISTINGS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchSettingError: payload };
    case APPROVE_LISTINGS_REQUEST:
      return { ...state, fetchInProgress: true, listing: [] };
    case APPROVE_LISTINGS_SUCCESS: {
      return {
        ...state,
        fetchInProgress: true,
        listing: [],
      };
    }
    case FETCH_LISTINGS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchSettingError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchListingRequest = () => ({ type: FETCH_LISTINGS_REQUEST });
const fetchListingRequestSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: response,
});
const fetchSettingError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});
const approveListingRequest = () => ({ type: APPROVE_LISTINGS_REQUEST });
const approveListingRequestSuccess = response => ({
  type: APPROVE_LISTINGS_SUCCESS,
  payload: response,
});
const approveListingError = e => ({
  type: APPROVE_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  dispatch(fetchListingRequest());
  return fetchAdminListings({})
    .then(response => {
      return dispatch(fetchListingRequestSuccess(response?.data?.data))
    })
    .catch(e => {
      dispatch(fetchSettingError(storableError(e)));
      throw e;
    });
};

export const handleApproveListing = (params) => (dispatch, getState, sdk) => {
  dispatch(approveListingRequest());
  return approveListing({ params })
    .then(res => {
      dispatch(approveListingRequestSuccess());
      return dispatch(loadData());
    })
    .catch(e => {
      dispatch(approveListingError(storableError(e)));
      throw e;
    });
};

export const handlenoActionListing = (params) => (dispatch, getState, sdk) => {
  dispatch(approveListingRequest());
  return noActionListing({ params })
    .then(res => {
      dispatch(approveListingRequestSuccess());
      return dispatch(loadData());
    })
    .catch(e => {
      dispatch(approveListingError(storableError(e)));
      throw e;
    });
};