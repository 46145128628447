import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { storableError } from '../../util/errors';
import { decodeLatLng } from '../../util/urlHelpers';
export const ASSET_NAME = 'landing-page';

export const SEARCH_LISTINGS_REQUEST = 'app/LandingPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/LandingPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/LandingPage/SEARCH_LISTINGS_ERROR';

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  listingsLocationCount: []
};

const resultIds = data => {
  const listings = data.data;
  return listings
    .filter(l => !l.attributes.deleted && l.attributes.state === 'published')
    .map(l => l.id);
};


const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:

      return {
        ...state,
        listingsLocationCount: payload.data,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };


    default:
      return state;
  }
};

export default listingPageReducer;


export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const loadData = (params, search, config) => async dispatch => {
  const pageAsset = { landingPage: `content/pages/${ASSET_NAME}.json` };

  const originList = [
    [-33.562644, 151.369884, -34.117175, 150.520934],      // sydney
    [-37.50211, 145.5507, -38.451455, 144.558953],         // melbourne
    [-26.96318, 153.331653, -27.778919, 152.667538],       // brisbane
    [-27.708149, 153.572698, -28.189258, 153.186512],      // gold coast
    [-34.647794, 138.772949, -35.348956, 138.4152],        // adelaide
    [-35.124403, 149.263516, -35.592776, 148.968683],      // canberra
    [-31.568682, 116.32375, -32.659495, 115.589016],       // perth
    [-42.675823, 147.617486, -43.025269, 147.147812],      // hobart
    [-12.303717, 131.051509, -12.547175, 130.789437],      // darwin
  ];

  const params = {
    page: 1,
    perPage: 1,
    'fields.listing': [
      'title',
    ],
    pub_isApprovedListingUpdate: true,
  };

  const queryListingsForOrigin = (origin) => async (dispatch, getState, sdk) => {
    try {
      const response = await sdk.listings.query({
        ...params,
        bounds: origin
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching listings for origin:', origin, error);
      return null;
    }
  }

  async function fetchListingsForAllOrigins() {
    const promises = originList.map(async (origin) => {
      const res = await dispatch(queryListingsForOrigin(origin));
      if (res) {
        return res;
      }
    });
    const results = await Promise.all(promises);

    const validResults = results.filter(result => result !== null);
    let listingsLocationCount = [];
    validResults.map((item, index) => {
      listingsLocationCount[index] = item?.meta?.totalItems;
    });

    dispatch(searchListingsSuccess(listingsLocationCount));
  }

  if (typeof window !== 'undefined') {
    await fetchListingsForAllOrigins();
  }

  return dispatch(fetchPageAssets(pageAsset, true));
};
