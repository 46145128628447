import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes, LINE_ITEM_HOUR } from '../../util/types';

import css from './OrderBreakdown.module.css';

import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;

const LineItemBasePriceMaybe = props => {
  const { lineItems, code, intl, duration, customerUnitPrice, isCustomer, marketplaceCurrency, listingType } = props;
  const isNightly = code === LINE_ITEM_NIGHT;
  const isDaily = code === LINE_ITEM_DAY;
  const isHourly = code === LINE_ITEM_HOUR;
  var translationKey = isNightly
    ? 'OrderBreakdown.baseUnitNight'
    : isDaily
    ? 'OrderBreakdown.baseUnitDay'
    : isHourly
    ? 'OrderBreakdown.baseUnitHour'
    : 'OrderBreakdown.baseUnitQuantity';

    if (listingType=='duration'){
      translationKey = 'OrderBreakdown.baseUnitSlot';
    }

  // Find correct line-item for given code prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/hour', or 'line-item/item'
  // These are defined in '../../util/types';
  const unitPurchase = lineItems.find(item => item.code === code && !item.reversal);

  const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;
  const unitPrice = unitPurchase ? formatMoney(intl, unitPurchase.unitPrice) : null;
  const customerUnitPriceFormat = customerUnitPrice ? formatMoney(intl, customerUnitPrice) : null;
  const total = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : null;
  const customerTotal = customerUnitPrice ? formatMoney(intl, new Money(customerUnitPrice?.amount * quantity, marketplaceCurrency )) : null;
  
  return quantity && total ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id={translationKey} values={isCustomer ? { unitPrice: customerUnitPriceFormat, quantity } : { unitPrice, quantity }} />
      </span>
      <span className={css.itemValue}>{isCustomer? customerTotal : total}</span>
    </div>
  ) : null;
};

LineItemBasePriceMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  code: propTypes.lineItemUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemBasePriceMaybe;
